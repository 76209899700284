.no-history {
	z-index: unset;
	margin-top: -30px
}

.pointservice__top {
	margin-top: 0;
}

.footer_text {
	display: block;
}

.prefecture-links__btn--link__disabled {
	background-color: #e5e5e5;
	color: gray;
}

.prefecture-links__content-item__disabled a {
	background-color: #e5e5e5;
	font-size: 1rem;
	color: gray;
	letter-spacing: .1em;
	position: relative;
	display: flex;
	align-items: center;
	-moz-column-gap: 10px;
	column-gap: 10px;
	padding: 10px 20px;
	height: 50px;
	width: 100%;
	border-radius: 5px
}
.new-props-item__thumb-img {
	aspect-ratio: 255 / 130;
}

.kyujin-item__thumb-img {
	aspect-ratio: 255 / 150;
}

.quick-search__selectbox {
	position: relative;
	width: 100%;
	height: 50px;
	display: block;
	padding-left: 20px;
	max-width: 100%;
	font-size: 1rem
}

.staff__img {
	margin-top:20px;
	border-radius:10px;
}

.menu__quick-search-word {
	padding-left: 9pt !important;
}

.support__quick-search {
	height: 155px;
}

.support .fixed-btn .support-fixed-item {
	display: flex;
	background-color: #6eb92b;
	width: calc(33.33% - 2px);
	border-radius: 4px;
	transition: all .2s;
	padding: initial;
}

.support .fixed-btn .support-fixed-item .support-fixed-item__link {
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 5px;
	width: 100%;
	font-size: 11px;
	line-height: 1.181818;
	text-align: left;
	padding: 11px 6px;
	border-radius: 4px;
}

.support .fixed-btn .support-fixed-item .support-fixed-item__link:before {
	content: none !important;
}

.menu__quick-search-word input[type=text] {
	font-size: unset !important;
}

.menu-btn {
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
}

.menu-btn__inner {
	flex-direction: column;
	margin-top: 7px;
}

.menu-btn .txt {
	font-size: .70rem;
	margin-top: 7px;
	font-weight: bold;
}
